import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import ScrollToTop from './elements/ScrollToTop';
import CookieConsent from 'react-cookie-consent';
import Lenis from 'lenis';

export default function App() {
  useEffect(() => {
    // Initialize Lenis
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // Smooth easing
      smooth: true,
    });

    // Scroll update callback
    const onScroll = (time) => {
      lenis.raf(time);
      requestAnimationFrame(onScroll);
    };
    requestAnimationFrame(onScroll);

    // Cleanup on unmount
    return () => {
      lenis.destroy();
    };
  }, []);

  return (
    <Router>
      <ScrollToTop />

      {/* Cookie Banner */}
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="userConsent"
        style={{
          background: 'rgba(0,0,0,.3)',
          left: 'auto',
          top: 'auto',
          bottom: '48px',
          padding: '16px 48px',
          width: '100%',
        }}
        buttonStyle={{
          color: '#000',
          background: '#D49C4D',
          fontSize: '14px',
          width: '100%',
          padding: '5px 10px',
        }}
        expires={365} // Cookie expires in 1 year
      >
        This website uses cookies to improve your experience.
      </CookieConsent>
      
      {/* Routes */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}
