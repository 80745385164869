import React, { useRef, useState, useCallback, useEffect } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import Swarm from '../elements/Swarm'
import { Beam } from './Beam'
import { Rainbow } from './Rainbow'
import { Prism } from './Prism'
import { Flare } from './Flare'
import { calculateRefractionAngle, lerp, lerpV3 } from '../util'

export default function Scene() {
  const [offsetX, setOffsetX] = useState(2) // Offset value for the x-axis
  const [offsetY, setOffsetY] = useState(5) // Initialize offsetY at 5

  const offsetYRef = useRef(offsetY) // Ref to hold the latest offsetY value

  // Update the ref whenever offsetY changes
  useEffect(() => {
    offsetYRef.current = offsetY
  }, [offsetY])

  // Adjust offsetX based on the device width
  useEffect(() => {
    const updateOffsetX = () => {
      if (window.innerWidth <= 768) {
        // Set offsetX to 0 for mobile devices
        setOffsetX(0)
      } else {
        // Default offsetX for larger devices
        setOffsetX(2)
      }
    }

    // Initial check and add resize listener
    updateOffsetX()
    window.addEventListener('resize', updateOffsetX)

    return () => window.removeEventListener('resize', updateOffsetX)
  }, [])

  // Scroll event handler to update offsetY based on scroll position
  useEffect(() => {
    const handleScroll = () => {
      const maxScroll = 500 // Maximum scroll value after which offsetY reaches 0
      const scrollPosition = window.scrollY
      const newOffsetY = Math.max(5 - (scrollPosition / maxScroll) * 5, 0)
      setOffsetY(newOffsetY)
    }

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const [isPrismHit, hitPrism] = useState(false)
  const flare = useRef(null)
  const ambient = useRef(null)
  const spot = useRef(null)
  const boxreflect = useRef(null)
  const rainbow = useRef(null)

  const rayOut = useCallback(() => hitPrism(false), [])
  const rayOver = useCallback((e) => {
    e.stopPropagation()
    hitPrism(true)
    rainbow.current.material.speed = 1
    rainbow.current.material.emissiveIntensity = 20
  }, [])

  const vec = new THREE.Vector3()
  const rayMove = useCallback(({ api, position, direction, normal }) => {
    if (!normal) return
    vec.toArray(api.positions, api.number++ * 3)
    flare.current.position.set(position.x, position.y, -0.5)
    flare.current.rotation.set(0, 0, -Math.atan2(direction.x, direction.y))

    // Use the latest offsetY value from the ref
    const currentOffsetY = offsetYRef.current

    // Adjust position.x and position.y by subtracting offsets
    let angleScreenCenter = Math.atan2(-(position.y - currentOffsetY), -(position.x - offsetX))
    const normalAngle = Math.atan2(normal.y, normal.x)
    const incidentAngle = angleScreenCenter - normalAngle
    const refractionAngle = calculateRefractionAngle(incidentAngle) * 6
    angleScreenCenter += refractionAngle
    rainbow.current.rotation.z = angleScreenCenter

    // Adjust the spot target position
    lerpV3(spot.current.target.position, [Math.cos(angleScreenCenter) + offsetX, Math.sin(angleScreenCenter) + currentOffsetY, 0], 0.05)
    spot.current.target.updateMatrixWorld()
  }, [])

  // Simulated pointer state
  const [simulatedPointer, setSimulatedPointer] = useState({ x: 0, y: 0 })

  // Periodically update simulated pointer on mobile devices
  useEffect(() => {
    let intervalId

    // Function to generate random pointer positions
    const generateRandomPointer = () => {
      const randomX = Math.random() * 2 - 1 // Random value between -1 and 1
      const randomY = Math.random() * 2 - 1
      setSimulatedPointer({ x: randomX, y: randomY })
    }

    // Check if the device is mobile
    if (window.innerWidth <= 768) {
      // Start interval to update simulatedPointer every second (1000ms)
      intervalId = setInterval(generateRandomPointer, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [])

  useFrame((state) => {
    const currentOffsetY = offsetYRef.current
    const isMobile = window.innerWidth <= 768
    const pointer = isMobile ? simulatedPointer : state.pointer

    boxreflect.current.setRay([(pointer.x * state.viewport.width) / 2, (pointer.y * state.viewport.height) / 2, 0], [offsetX, currentOffsetY, 0])

    lerp(rainbow.current.material, 'emissiveIntensity', isPrismHit ? 2.5 : 0, 0.1)
    spot.current.intensity = rainbow.current.material.emissiveIntensity
    lerp(ambient.current, 'intensity', 0, 0.225)
  })

  return (
    <>
      <ambientLight ref={ambient} intensity={5} />
      <spotLight ref={spot} intensity={1} distance={7} angle={1} penumbra={1} position={[0, 0, 1]} />
      <Swarm count={50} />
      <Beam ref={boxreflect} bounce={10} far={20}>
        <Prism scale="0.55" position={[offsetX, offsetY, 0]} onRayOver={rayOver} onRayOut={rayOut} onRayMove={rayMove} />
      </Beam>
      <Rainbow ref={rainbow} startRadius={0} endRadius={0.5} fade={0} position={[offsetX, offsetY, 0]} />
      <Flare ref={flare} visible={isPrismHit} renderOrder={10} scale={1.25} streak={[5.5, 20, 1]} />
    </>
  )
}
