import * as React from 'react'
import styles from './Hero.module.css'
import { AnimatePresence, motion, useInView } from 'framer-motion'
import heroTexts from './heroTexts.json'

function Hero({ text = heroTexts.defaultText, subText = heroTexts.subText }) {
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <div className={styles.heroWrapper}>
      {/* Main Text Animation */}
      <h1 className={styles.heroH1}>
        <AnimatePresence>
          {text.split(' ').map((word, i) => (
            <motion.span
              ref={ref}
              key={i}
              initial={{ opacity: 0, y: -10 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              exit="hidden"
              className="wordWrapper"
              transition={{ duration: 0.5, delay: i * 0.1 }}
              style={{ display: 'inline-block', marginRight: '8px' }}>
              {word}
            </motion.span>
          ))}
        </AnimatePresence>
      </h1>
    </div>
  )
}

export default Hero
