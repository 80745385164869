import React from 'react'
import { Link } from 'react-router-dom'
import styles from './Navbar.module.css'
import Logo from '../Logo/Logo'

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <Logo />
      <nav className={styles.navLinks}>
        <Link to="/" className={styles.navLink}>
          Home
        </Link>
        <Link to="/contact" className={styles.navLink}>
          Contact Us
        </Link>
      </nav>
    </div>
  )
}

export default Navbar
