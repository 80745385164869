import { useState, useRef, useEffect } from 'react'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import SineWave from '../SineWave/SineWave'
import RevealingText from '../RevealingText/RevealingText'
import styles from './Alignment.module.css'
import DivisionSolo from '../ContactList/DivisionSolo'

function Alignment() {
  const divisionsData = [
    {
      imageSrc: './img/PA.png',
      headlineText: '.PA',
      subHeadlineText: 'Feasibility Study',
      descriptionText:
        'The D&I team conducts thorough market research and feasibility studies to evaluate the viability and potential of new products and technologies.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '.VC',
      subHeadlineText: 'MVP Development',
      descriptionText: 'The D&I team creates and implements marketing strategies to support the strategies and launch of Minimum Viable Products.'
    }
  ]
  const divisionsDataTwo = [
    {
      imageSrc: './img/PA.png',
      headlineText: '',
      subHeadlineText: 'Seed Funding',
      descriptionText: 'The focus is on ensuring the portfolio of companies gains traction and achieves a growth trajectory in appropriate markets.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '',
      subHeadlineText: 'Growth Funding',
      descriptionText: 'Marketing efforts are scaled to support the growth and expansion of startups.'
    }
  ]

  const [amplitude, setAmplitude] = useState(125)
  const sineWaveRef = useRef(null)
  const scrollY = useMotionValue(0)
  const amplitudeTransform = useTransform(scrollY, [0, 1], [125, 0])

  useEffect(() => {
    const updateScroll = () => {
      const element = sineWaveRef.current
      if (element) {
        const rect = element.getBoundingClientRect()
        const windowHeight = window.innerHeight

        // Calculate scroll position as a ratio (0 at "top top", 1 at "end end")
        const progress = Math.min(1, Math.max(0, ((windowHeight - rect.top) / windowHeight) * 1.25))

        // Update scrollY with progress (0 to 1)
        scrollY.set(progress)
      }
    }

    window.addEventListener('scroll', updateScroll)
    return () => window.removeEventListener('scroll', updateScroll)
  }, [scrollY])

  // Sync the transformed amplitude value to the amplitude state
  useEffect(() => {
    return amplitudeTransform.on('change', (latest) => setAmplitude(latest))
  }, [amplitudeTransform])

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.labelWrapper}>
          <div className={styles.labelSingle}>Feasability</div>
          <div className={styles.labelSingle}>MVP</div>
          <div className={styles.labelSingle}>Seed</div>
          <div className={styles.labelSingle}>Growth</div>
        </div>
        <motion.div ref={sineWaveRef} className={styles.alignment}>
          <SineWave amplitude={amplitude} frequency={0.35} vertPosition={-20} timeOffset={0} dotColor="#ffffff" />
          <SineWave amplitude={amplitude} frequency={0.35} vertPosition={+20} timeOffset={9} dotColor="#868686" />
        </motion.div>
        <div className="alignmentReveal">
          <RevealingText text="Approach" fontSize="headline" float="left" />
          <RevealingText
            text="At Continuous Ventures, we do more than just invest. Our Disruption & Intelligence team—a group of experienced strategists, designers, and technologists—takes an active role in accelerating your growth. From feasibility studies to market entry and scaling, we offer hands-on support at every stage of development, ensuring success with minimized risk."
            fontSize="small"
            float="left"
            tag="p"
          />
        </div>
        <div className={styles.listWrapper}>
          {/* Pass all data to a single DivisionSolo component */}
          <DivisionSolo divisionData={divisionsData} />
          <DivisionSolo divisionData={divisionsDataTwo} />
        </div>
      </div>
    </>
  )
}

export default Alignment
