import React from 'react'
import styles from './PartnersWall.module.css'
import { InteractiveMarquee } from '../Values/Marquee'
import TerrainComponent from '../../elements/Terrain'

const PartnersWall = () => {
  // Total number of logos
  const totalLogos = 6 // Replace this with the actual number of logos you have
  const totalLogosB = 6 // Replace this with the actual number of logos you have

  // Generate an array of logo paths
  const logos = Array.from({ length: totalLogos }, (_, index) => `img/partners/logos_a_${String(index + 1).padStart(2, '0')}.png`)
  const logosb = Array.from({ length: totalLogosB }, (_, index) => `img/partners/logos_b_${String(index + 1).padStart(2, '0')}.png`)

  return (
    <div className={styles.LogoWallWrapper}>
      <div className={styles.LogoWall}>
        <InteractiveMarquee speed="0.02" className={styles.marequeeText}>
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`Partner Logo ${index + 1}`} />
          ))}
        </InteractiveMarquee>
        <InteractiveMarquee speed="-0.02" className={styles.marequeeText}>
          {logosb.map((logo, index) => (
            <img key={index} src={logo} alt={`Partner Logo ${index + 1}`} />
          ))}
        </InteractiveMarquee>
      </div>

      <TerrainComponent className={styles.terrainCanvas} />
    </div>
  )
}

export default PartnersWall
