import React, { useRef } from 'react'
import styles from './Divisions.module.css'
import RevealingText from '../RevealingText/RevealingText'
import { motion, useScroll, useTransform } from 'framer-motion'

const DivisionSolo = ({ divisionData }) => {
  const ref = useRef(null)

  // Track scroll progress of the target element
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['end 0.5', '1 0'] // Adjust based on layout
  })

  // Calculate the active index using scroll progress
  const activeIndex = useTransform(
    scrollYProgress,
    divisionData.map((_, index) => index / (divisionData.length - 1)), // Map scroll to indices
    divisionData.map((_, index) => index)
  )

  return (
    <div className={styles.divisionsSolo}>
      {/* Sticky Images */}

      {/* Scrolling Text */}
      <div className={styles.textContainer}>
        {divisionData.map((data, index) => (
          <div key={index} className={`${styles.text} rowitem`}>
            {/* <RevealingText text={data.headlineText} fontSize="headline" /> */}
            <RevealingText text={data.subHeadlineText} fontSize="headline" tag="h3" />
            <RevealingText text={data.descriptionText} fontSize="small" tag="p" />
          </div>
        ))}
      </div>
    </div>
  )
}

export default DivisionSolo
