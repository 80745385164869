import React, { useRef, useEffect } from 'react'
import { motion, useSpring, useTransform, useMotionValue } from 'framer-motion'
import normalizeWheel from 'normalize-wheel'
import { useRafLoop } from 'react-use'
import { useWindowSize } from '@react-hook/window-size'
import styles from './Marquee.module.css'

const MarqueeItem = ({ children, speed }) => {
  const itemRef = useRef(null)
  const rectRef = useRef(null)
  const x = useRef(0)
  const [width, height] = useWindowSize()

  const setX = () => {
    if (!itemRef.current || !rectRef.current) {
      return
    }

    const xPercentage = (x.current / rectRef.current.width) * 100

    if (xPercentage < -100) {
      x.current = 0
    }

    if (xPercentage > 0) {
      x.current = -rectRef.current.width
    }

    itemRef.current.style.transform = `translate3d(${xPercentage}%, 0, 0)`
  }

  useEffect(() => {
    if (itemRef.current) {
      rectRef.current = itemRef.current.getBoundingClientRect()
    }
  }, [width, height])

  const loop = () => {
    x.current -= speed.get()
    setX()
  }

  const [, loopStart] = useRafLoop(loop, false)

  useEffect(() => {
    loopStart()
  }, [])

  return (
    <motion.div className={styles.item} ref={itemRef}>
      {children}
    </motion.div>
  )
}

export const InteractiveMarquee = ({ speed = 0, threshold = 0.014, wheelFactor = 1.8, dragFactor = 1.2, children }) => {
  const marqueeRef = useRef(null)
  const slowDown = useRef(false)
  const constraintsRef = useRef(null)

  const x = useRef(0)
  const [wWidth] = useWindowSize()
  const speedSpring = useSpring(speed, {
    damping: 40,
    stiffness: 90,
    mass: 5
  })

  const skewX = useTransform(speedSpring, [-wWidth * 0.5, 0, wWidth * 0.5], [1, 0, 1])

  const loop = () => {
    if (slowDown.current || Math.abs(x.current) < threshold) {
      return
    }

    x.current *= 0.66

    // if (x.current < 0) {
    //   x.current = Math.min(x.current, 0)
    // } else {
    //   x.current = Math.max(x.current, 0)
    // }

    speedSpring.set(speed + x.current)
  }

  useRafLoop(loop)

  return (
    <>
      <motion.div ref={constraintsRef} />
      <motion.div className={styles.marquee} ref={marqueeRef} style={{ skewX }}>
        <MarqueeItem speed={speedSpring}>{children}</MarqueeItem>
        <MarqueeItem speed={speedSpring}>{children}</MarqueeItem>
      </motion.div>
    </>
  )
}
