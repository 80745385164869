import * as THREE from 'three'
import React, { useRef, useMemo } from 'react'
import { useFrame } from '@react-three/fiber'

function Swarm({ count, color = 'red', sizeMult = '1' }) {
  const mesh = useRef()

  const dummy = useMemo(() => new THREE.Object3D(), [])
  // Generate some random positions, speed factors and timings
  const particles = useMemo(() => {
    const temp = []
    for (let i = 0; i < count; i++) {
      const t = Math.random() * 100
      const factor = 20 + Math.random() * 100
      const speed = 0.001 + Math.random() / 200
      const xFactor = -20 + Math.random() * 50
      const yFactor = -20 + Math.random() * 50
      const zFactor = -20 + Math.random() * 50
      temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
    }
    return temp
  }, [count])
  // The innards of this hook will run every frame
  useFrame((state) => {
    // Run through the randomized data to calculate some movement
    particles.forEach((particle, i) => {
      let { t, factor, speed, xFactor, yFactor, zFactor } = particle
      // There is no sense or reason to any of this, just messing around with trigonometric functions
      t = particle.t += speed / 4
      const a = Math.cos(t) + Math.sin(t * 1) / 20
      const b = Math.sin(t) + Math.cos(t * 2) / 20
      const s = Math.cos(t)
      // Update the dummy object
      dummy.position.set(
        (particle.mx / 30) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
        (particle.my / 30) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
        (particle.my / 4) * 2 * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
      )
      dummy.scale.set(s / 4, s / 4, s / 4) * sizeMult
      // dummy.rotation.set(s * 5, s * 5, s * 5)
      dummy.updateMatrix()
      // And apply the matrix to the instanced item
      mesh.current.setMatrixAt(i, dummy.matrix)
    })
    mesh.current.instanceMatrix.needsUpdate = true
  })
  return (
    <>
      <instancedMesh ref={mesh} args={[null, null, count]}>
        <sphereGeometry args={[0.3, 2]} />
        <meshStandardMaterial color={color} emissive="#aac7dc" emissiveIntensity={0.1} />
      </instancedMesh>
    </>
  )
}

export default Swarm
