import React, { useRef, useState, useEffect } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Environment, Cloud, Clouds, useGLTF } from '@react-three/drei'
import * as THREE from 'three'
import { useScroll, useSpring, useTransform, motion } from 'framer-motion'
import styles from './Process.module.css'
import { useInView } from 'react-intersection-observer'

const DisableRender = () => useFrame(() => null, 1000)

function Sky({ scrollYProgress }) {
  const ref = useRef()
  const [cloudConfig, setCloudConfig] = useState({
    growth: 0,
    volume: 0
  })

  const animatedGrowth = useSpring(useTransform(scrollYProgress, [0, 1.0], [0, 105]), {
    stiffness: 100,
    damping: 30
  })
  const animatedVolume = useSpring(useTransform(scrollYProgress, [0, 0.8], [0, 10.5]), {
    stiffness: 100,
    damping: 30
  })

  useEffect(() => {
    const unsubscribeGrowth = animatedGrowth.on('change', (value) => {
      setCloudConfig((prev) => ({ ...prev, growth: value }))
    })
    const unsubscribeVolume = animatedVolume.on('change', (value) => {
      setCloudConfig((prev) => ({ ...prev, volume: value }))
    })

    return () => {
      unsubscribeGrowth()
      unsubscribeVolume()
    }
  }, [animatedGrowth, animatedVolume])

  useFrame((state) => {
    if (ref.current) {
      ref.current.rotation.y = Math.cos(state.clock.elapsedTime / 2) / 2
      ref.current.rotation.x = Math.sin(state.clock.elapsedTime / 2) / 2
    }
  })

  return (
    <group ref={ref}>
      {/* Environment preset for realistic sunset lighting */}
      <Environment preset="dawn" />
      {/* Clouds with dynamic scrolling effects */}
      <Clouds material={THREE.MeshLambertMaterial} limit={100}>
        <Cloud bounds={[6, 1, 1]} color="#06050a" seed={5} position={[0, -2, 0]} growth={cloudConfig.growth} volume={cloudConfig.volume} />
      </Clouds>
    </group>
  )
}

function Rocket({ scrollYProgress }) {
  const { nodes } = useGLTF('gltf/rocket.glb')
  const rocketRef = useRef()

  // Define initial and final positions for the rocket's ascent
  const initialY = -8
  const finalY = 50

  // Map scroll progress to the rocket's vertical position
  const animatedYPosition = useSpring(useTransform(scrollYProgress, [0, 1], [initialY, finalY]), { stiffness: 100, damping: 30 })

  useFrame((state, delta) => {
    if (rocketRef.current) {
      const positionY = animatedYPosition.get()

      // Animate rotation for a liftoff effect
      rocketRef.current.rotation.x = Math.sin(state.clock.elapsedTime * 2) * 0.1
      rocketRef.current.rotation.z = Math.sin(state.clock.elapsedTime * 2) * 0.1

      // Update position based on scroll progress
      rocketRef.current.position.y = positionY
    }
  })

  return (
    <group ref={rocketRef} dispose={null} position={[0, initialY, -10]} scale={1}>
      <mesh castShadow receiveShadow geometry={nodes.saturn_v_rese_kao_jel.geometry} position={[-0.031, -0.17, 0]} scale={0.379}>
        <meshStandardMaterial color="#7a6e2e" metalness={1.0} roughness={0.3} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.saturn_v_motori.geometry} position={[-0.088, 1.802, 0]} scale={0.379}>
        <meshStandardMaterial color="#7a6e2e" metalness={0.9} roughness={0.5} />
      </mesh>
      <mesh castShadow receiveShadow geometry={nodes.saturn_v.geometry} position={[-0.031, 2.486, 0]} scale={0.379}>
        <meshStandardMaterial color="black" metalness={0.8} roughness={0.2} />
      </mesh>
    </group>
  )
}

useGLTF.preload('gltf/rocket.glb')

function Process() {
  const { ref, inView } = useInView()

  // Use useScroll without specifying a container to track the entire page scroll
  const { scrollYProgress } = useScroll()

  const [intensity, setIntensity] = useState(1)
  const animatedIntensity = useSpring(useTransform(scrollYProgress, [0, 0.5], [1, 0]), {
    stiffness: 100,
    damping: 30
  })

  useEffect(() => {
    const unsubscribe = animatedIntensity.on('change', (value) => {
      setIntensity(value)
    })
    return () => {
      unsubscribe()
    }
  }, [animatedIntensity])

  return (
    <div ref={ref} className={styles.canvasContainer} style={{ height: '150vh', overflow: 'hidden' }}>
      {/* Remove the motion.div with containerRef since we're tracking the entire page scroll */}
      <div className={styles.textOverlay}>
        {/* <h4>Skyrocket</h4> */}
        {/* <div className={styles.skyrocketList}>
          <div className={styles.skyrocketListSingle}>
            <h5>Item No 1</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ipsum, luctus accumsan maximus sit amet, tempus vitae lorem. Sed blandit
              efficitur tellus vel ullamcorper.
            </p>
          </div>
          <div className={styles.skyrocketListSingle}>
            <h5>Item No 1</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ipsum, luctus accumsan maximus sit amet, tempus vitae lorem. Sed blandit
              efficitur tellus vel ullamcorper.
            </p>
          </div>
          <div className={styles.skyrocketListSingle}>
            <h5>Item No 1</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ipsum, luctus accumsan maximus sit amet, tempus vitae lorem. Sed blandit
              efficitur tellus vel ullamcorper.
            </p>
          </div>
          <div className={styles.skyrocketListSingle}>
            <h5>Item No 1</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec justo ipsum, luctus accumsan maximus sit amet, tempus vitae lorem. Sed blandit
              efficitur tellus vel ullamcorper.
            </p>
          </div>
        </div> */}
      </div>
      <div className={styles.canvasWrapper} style={{ height: '200vh' }}>
        <Canvas
          orthographic
          style={{
            background: 'linear-gradient(0deg, #0d0004, #161721, #1c2228, #100f14, #000000)'
          }}
          gl={{ antialias: false, preserveDrawingBuffer: true }}
          camera={{ position: [0, 0, 100], zoom: 70 }}>
          <Sky scrollYProgress={scrollYProgress} />
          <ambientLight intensity={intensity} />
          <pointLight position={[10, -10, 0]} intensity={0.05} />
          <pointLight position={[0, 10, 0]} intensity={0.25} />
          <pointLight position={[-10, 0, 0]} intensity={0.05} />
          <Rocket scrollYProgress={scrollYProgress} />
          {!inView && <DisableRender />}
        </Canvas>
      </div>
    </div>
  )
}

export default Process
