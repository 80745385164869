import React, { useEffect, useRef } from 'react'

const SineWave = ({ amplitude, frequency, vertPosition, timeOffset, dotColor }) => {
  const waveRef = useRef(null)
  const numDots = 32
  const speed = 0.01

  useEffect(() => {
    const dots = Array.from(waveRef.current.children)
    const animateWave = () => {
      const time = (Date.now() * speed) / 10

      dots.forEach((dot, index) => {
        const x = index * (window.innerWidth / numDots)
        const y = Math.sin(index * frequency + time + timeOffset) * amplitude + vertPosition
        dot.style.transform = `translate(${x}px, ${y}px)`
      })

      requestAnimationFrame(animateWave)
    }

    animateWave()
  }, [amplitude, frequency]) // Update on amplitude or frequency change

  return (
    <div id="sine-wave" ref={waveRef}>
      {Array.from({ length: numDots }).map((_, i) => (
        <div
          className="dot"
          style={{
            backgroundColor: dotColor
          }}
          key={i}
        />
      ))}
    </div>
  )
}

export default SineWave
