import * as React from 'react'
import { useRef } from 'react'
import { motion, useScroll } from 'framer-motion'
import { getRevealAnimation } from '../../utils/revealEffect' // Import the utility function
import styles from './RevealingText.module.css'

function RevealingText({
  text = 'Default Value this is some long text this is some long text this is some long text',
  fontSize = '',
  tag = 'h2',
  float = 'left',
  fullscreen = ''
}) {
  const refText = useRef(null)
  const totalLetters = text.replace(/\s/g, '').length
  let letterIndex = 0

  const { scrollYProgress } = useScroll({
    target: refText
  })

  const renderDynamicTag = (content, ref = null) => {
    return React.createElement(
      tag,
      { className: styles.heroH2, ref }, // Props for the dynamic tag
      content
    )
  }

  const splitText = text.split(' ').map((word, i) => (
    <span key={i} style={{ display: 'inline-block', marginRight: '0.5em' }}>
      {word.split('').map((char, j) => (
        <span key={j} className="charWrapper">
          {char}
        </span>
      ))}
    </span>
  ))

  const animatedText = text.split(' ').map((word, i) => (
    <span key={i} style={{ display: 'inline-block', marginRight: '0.5em' }}>
      {word.split('').map((char, j) => {
        const animationProps = getRevealAnimation(scrollYProgress, letterIndex++, totalLetters)
        return (
          <motion.span key={j} style={{ opacity: animationProps.opacity }} className="charWrapper">
            {char}
          </motion.span>
        )
      })}
    </span>
  ))

  return (
    <div
      className={`${styles.revealingTextWrapper} globalModifier ${float ? styles[float] : ''} ${fullscreen ? styles[fullscreen] : ''} ${
        fontSize ? styles[fontSize] : ''
      }`}>
      <div className={styles.RevealingTextHeadlineWrapper}>
        <div className={`${styles.Fixed} globalModifier`}>{renderDynamicTag(splitText)}</div>
        <div className={`${styles.positioner} globalModifier`}>{renderDynamicTag(animatedText, refText)}</div>
      </div>
    </div>
  )
}

export default RevealingText
