import { useTransform } from 'framer-motion'

export function getRevealAnimation(scrollYProgress, letterIndex, totalLetters) {
  const delayFactor = 1.8 - letterIndex / totalLetters

  const start = delayFactor * 0.5
  const end = Math.min(start + 0.2, 1)

  const opacity = useTransform(scrollYProgress, [start, end], [1, 0])
  const y = useTransform(scrollYProgress, [start, end], [0, 20])

  return { opacity, y }
}
