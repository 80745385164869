import { useState } from 'react'
// npm install react-hook-form @web3forms/react
import { useForm } from 'react-hook-form'
import useWeb3Forms from '@web3forms/react'
import styles from './Contact.module.css'
import { InteractiveMarquee } from '../Values/Marquee'
import RevealingText from '../RevealingText/RevealingText'

export default function Contact() {
  const { register, reset, handleSubmit } = useForm()

  const [isSuccess, setIsSuccess] = useState(false)
  const [result, setResult] = useState(null)

  const accessKey = 'e94e6320-0af4-4e59-9551-7aff7446239d'

  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: '** CONTINUOUS Website **',
      subject: 'New In'
      // ... other settings
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true)
      setResult(msg)
      reset()
    },
    onError: (msg, data) => {
      setIsSuccess(false)
      setResult(msg)
    }
  })

  return (
    <div className={styles.contact}>
      <InteractiveMarquee speed="1.5" className={styles.marqueText}>
        <h4 className={styles.marqueHeadline}>Get in Touch</h4>
        <div className={styles.marqueText}>
          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
            <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
          </svg>
        </div>
        <h4 className={styles.marqueHeadline}>Get in Touch</h4>
        <div className={styles.marqueText}>
          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
            <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
          </svg>
        </div>
        <h4 className={styles.marqueHeadline}>Get in Touch</h4>
        <div className={styles.marqueText}>
          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
            <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
          </svg>
        </div>
        <h4 className={styles.marqueHeadline}>Get in Touch</h4>
        <div className={styles.marqueText}>
          <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
            <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
          </svg>
        </div>
      </InteractiveMarquee>

      <div className={styles.contactMaxWidth}>
        <div className="contactForm">
          <RevealingText
            text="Submit your pitch through the form below. Once your project is reviewed, you will receive a notification for the next steps."
            fontSize="small"
            tag="p"
          />
          <RevealingText
            text="If you meet the criteria, the D&I team will invite you for a Glimpse where you will present your startup and pitch to the team."
            fontSize="small"
            tag="p"
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          {/* Personal Section */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <div className={styles.formHeadline}>Personal</div>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                First Name <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Name', { required: true })} />
            </div>
            <div className={styles.formCol}>
              <label>
                Last Name <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Last Name', { required: true })} />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                E-Mail <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="e-mail" {...register('E-Mail', { required: true })} />
            </div>
          </div>

          {/* Company Section */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <div className={styles.formHeadline}>Company</div>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                Company Name <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Company Name', { required: true })} />
            </div>
            <div className={styles.formCol}>
              <label>
                Industry <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Industry', { required: true })} />
            </div>
          </div>

          {/* Industry and Team Size */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                Job Title <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Job Title', { required: true })} />
            </div>

            <div className={styles.formCol}>
              <label>
                Team Size <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Team Size', { required: true })} />
            </div>
          </div>

          {/* Additional Information */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <div className={styles.formHeadline}>Additional Information</div>
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                Startup Phase <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Startup Phase', { required: true })} />
            </div>
            <div className={styles.formCol}>
              <label>
                Investment Ask <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Investment Ask', { required: true })} />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                Location <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Location', { required: true })} />
            </div>
            <div className={styles.formCol}>
              <label>
                Website <span className={styles.required}>*</span>
              </label>
              <input className={styles.input} type="text" {...register('Website', { required: true })} />
            </div>
          </div>
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>Pitchdeck Link</label>
              <input className={styles.input} type="url" {...register('Pitchdeck', { required: true })} />
            </div>
          </div>

          {/* Referred By Section */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <div className={styles.formHeadline}>Referred By</div>
            </div>
          </div>
          <div className={styles.formRow} id="referral">
            <div className={styles.formCol}>
              <label>Referral Source</label>

              <div className={styles.toggleContainer}>
                {/* None Option */}
                <input type="radio" id="none" value="None" {...register('ReferredBy', { required: false })} className={styles.input} />
                <label htmlFor="none" className={styles.toggleOption}>
                  None
                </label>

                {/* Internal Option */}
                <input type="radio" id="internal" value="Internal" {...register('ReferredBy', { required: false })} className={styles.input} />
                <label htmlFor="internal" className={styles.toggleOption}>
                  Internal
                </label>

                {/* External Option */}
                <input type="radio" id="external" value="External" {...register('ReferredBy', { required: false })} className={styles.input} />
                <label htmlFor="external" className={styles.toggleOption}>
                  External
                </label>
              </div>
            </div>
            <div className={styles.formCol}>
              <label>Referral Person or Company</label>
              <input className={styles.input} type="text" {...register('Referal Person', { required: false })} />
            </div>
          </div>

          {/* Message Section */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <label>
                Message <span className={styles.required}>*</span>
              </label>
              <textarea rows="5" className={styles.input} {...register('Message', { required: true })}></textarea>
            </div>
          </div>

          {/* Submit Button */}
          <div className={styles.formRow}>
            <div className={styles.formCol}>
              <button className={styles.inputButton} type="submit">
                Submit Form
              </button>
            </div>
          </div>
        </form>

        <div>{result}</div>
      </div>
    </div>
  )
}
