import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Hero from '../components/Hero/Hero'
import RevealingText from '../components/RevealingText/RevealingText'
import Alignment from '../components/Alignment/Alignment'
import Divisions from '../components/Divisions/Divisions'
import Values from '../components/Values/Values'
import PartnersWall from '../components/PartnersWall/PartnersWall'
import WhereWeAre from '../components/WhereWeAre/WhereWeAre'
import CanvasWrapper from '../elements/CanvasWrapper'
import Process from '../components/Process/Process'
import BlogPosts from '../components/BlogPosts/BlogPosts'
import Contact from '../components/Contact/Contact'
// import InertiaImage from './elements/ParallaxImage';

export default function Home() {
  return (
    <>
      <Navbar />
      <CanvasWrapper />

      <Hero />
      <div className="secondSection">
        <RevealingText
          // fullscreen="fullscreen"
          float="right"
          text="Founded in 2018, Continuous Ventures is an award winning global venture capital private accelerator firm, dedicated to driving innovation through cutting-edge technology."
        />
        <RevealingText
          // fullscreen="fullscreen"
          float="right"
          text="With headquarters in Dubai, and additional offices in Dublin and New York, we provide startups with the funding, mentorship, and resources they need to succeed in an increasingly competitive landscape."
        />
      </div>
      <Process />
      <Alignment />
      <Divisions />

      {/* <Values /> */}
      <BlogPosts />
      <PartnersWall />
      <WhereWeAre />
      <Contact />
    </>
  )
}
