import React from 'react'
import { Canvas } from '@react-three/fiber'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { Stats } from '../Stats'
import Scene from './Scene'
import { useInView } from 'react-intersection-observer'
import { useFrame } from '@react-three/fiber'

const DisableRender = () => useFrame(() => null, 1000)

export default function CanvasWrapper() {
  const { ref, inView } = useInView()

  return (
    <div ref={ref}>
      <Canvas className="canvasHero" orthographic gl={{ antialias: false }} camera={{ position: [0, 0, 100], zoom: 70 }}>
        {!inView && <DisableRender />}

        <color attach="background" args={['black']} />
        {/* <Stats /> */}
        <Scene />
        <EffectComposer disableNormalPass>
          {/* <Glitch strength={100.3} delay={0.1} columns={1} /> */}

          <Bloom mipmapBlur levels={9} intensity={1.5} luminanceThreshold={1} luminanceSmoothing={1} />
        </EffectComposer>
      </Canvas>
    </div>
  )
}
