import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Contact from '../components/Contact/Contact'
import HeroContact from '../components/HeroContact/HeroContact'
import ContactList from '../components/ContactList/ContactList'
import RevealingText from '../components/RevealingText/RevealingText'

export default function ContactUs() {
  return (
    <>
      <Navbar />

      <ContactList />

      <Contact />
    </>
  )
}
