import React from 'react'
import { motion, useAnimation, useScroll } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import styles from './Logo.module.css'

const Logo = () => {
  const controls = useAnimation()
  const { scrollY } = useScroll()
  const location = useLocation()

  const isHomePage = location.pathname === '/' || location.pathname === '/home' // Check for both root and /home

  React.useEffect(() => {
    if (isHomePage) {
      const unsubscribe = scrollY.on('change', (current) => {
        if (current > 100) {
          controls.start({
            scale: 1.5, // Shrink to original size
            top: '32px', // Move to the top
            translateY: '0%' // Align with the top
          })
        } else {
          controls.start({
            scale: 3.8, // Stay enlarged
            top: '50%', // Stay at the center vertically
            translateY: '-50%' // Center vertically
          })
        }
      })

      return () => {
        unsubscribe() // Cleanup the scroll listener on unmount
      }
    } else {
      // If not on the home page or root, set the logo to its small state
      controls.start({
        scale: 1.5,
        top: '32px',
        translateY: '0%'
      })
    }
  }, [controls, scrollY, isHomePage])

  return (
    <motion.div
      animate={controls}
      className={styles.logoWrapper}
      initial={{
        scale: isHomePage ? 3.8 : 1.5, // Enlarged on home page or root, small on others
        top: isHomePage ? '50%' : '16%', // Centered on home page or root, at the top on others
        translateY: isHomePage ? '-50%' : '0%' // Adjust vertical alignment
      }}
      transition={{
        type: 'spring',
        stiffness: 100,
        damping: 20
      }}
      style={{
        position: 'fixed',
        top: '48px',
        left: '5vw' // Align the logo to the left
      }}>
      <svg className={styles.logo} width="122" height="64" viewBox="0 0 122 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_68_422)">
          <path
            d="M121.721 55.9259L120.991 55.4622C108.182 47.3133 85.3553 38.4865 63.175 38.4865C36.3307 38.4865 13.3207 47.3507 0.736574 55.6473L0.000488281 56.1318V0L0.730336 0.463693C12.7156 8.09071 36.1664 20.8849 58.5464 20.8849C86.5904 20.8849 109.702 8.40677 120.985 0.968972L121.721 0.484486V55.928V55.9259ZM63.175 37.5051C85.1432 37.5051 107.727 46.0574 120.766 54.1689V2.27272C109.147 9.8124 86.239 21.8643 58.5485 21.8643C36.3765 21.8643 13.2334 9.48179 0.954905 1.75704V54.3436C13.8011 46.1135 36.6364 37.5051 63.1729 37.5051H63.175Z"
            fill="#D49C4D"
          />
          <path
            d="M61.1332 38.1659C53.0362 38.1659 43.28 36.1905 32.8625 29.8173L32.168 29.3932L32.8334 28.9274C33.0891 28.7486 39.2024 24.5254 49.204 22.3671C58.428 20.3751 72.792 19.8885 88.8362 28.8712L89.5515 29.2726L88.8965 29.7633C88.6387 29.9567 82.461 34.5312 72.4219 36.8705C69.2218 37.617 65.4062 38.168 61.1311 38.168L61.1332 38.1659ZM34.1808 29.3474C49.4618 38.4175 63.2167 37.8977 72.1204 35.8329C80.0676 33.9885 85.6569 30.6262 87.5325 29.3848C72.1204 21.0155 58.3677 21.4958 49.4889 23.4026C41.5895 25.0993 36.0563 28.1913 34.1787 29.3453L34.1808 29.3474Z"
            fill="#D49C4D"
          />
          <path
            d="M0.000614496 56.2007V0.333008L0.828192 1.24584C5.97872 6.93075 11.0274 11.8921 16.261 16.4105C21.9959 21.3635 27.5914 25.4993 33.3636 29.0549L34.0706 29.4916L33.3491 29.9033C22.1352 36.3118 11.491 44.6479 0.811557 55.3856L-0.00146484 56.2028L0.000614496 56.2007ZM0.955031 2.82198V53.8739C11.1979 43.6893 21.424 35.6901 32.168 29.4604C26.5995 25.9775 21.187 21.9477 15.6435 17.1632C10.6614 12.859 5.84772 8.15964 0.955031 2.82198Z"
            fill="#D49C4D"
          />
          <path
            d="M121.721 55.9262L120.91 55.3856C110.23 44.6479 99.5884 36.3118 88.3724 29.9033L87.6509 29.4916L88.3579 29.0549C94.1301 25.4993 99.7256 21.3635 105.46 16.4105C110.694 11.89 115.743 6.93075 120.893 1.24584L121.721 0.333008V55.9262ZM89.5535 29.4604C100.295 35.6901 110.524 43.6914 120.766 53.8739V2.82198C115.876 8.15964 111.06 12.859 106.078 17.1611C100.537 21.9477 95.124 25.9754 89.5556 29.4583L89.5535 29.4604Z"
            fill="#D49C4D"
          />
          <path
            d="M25.2187 56.6977C25.1376 56.7621 25.0129 56.8349 24.8403 56.9181C24.6677 57.0012 24.466 57.074 24.2331 57.1343C24.0002 57.1967 23.7403 57.2237 23.4575 57.2196C23.0022 57.2133 22.5925 57.1322 22.2307 56.9742C21.8689 56.8162 21.5612 56.602 21.3054 56.3317C21.0497 56.0614 20.8542 55.7495 20.719 55.3981C20.5818 55.0467 20.5132 54.6703 20.5132 54.269C20.5132 53.8344 20.5818 53.431 20.7232 53.0588C20.8625 52.6866 21.06 52.3643 21.3179 52.094C21.5757 51.8237 21.8814 51.6137 22.2349 51.4619C22.5884 51.3122 22.9772 51.2373 23.4014 51.2373C23.7653 51.2373 24.0938 51.2872 24.387 51.387C24.6802 51.4868 24.9235 51.5991 25.1231 51.728L24.753 52.6055C24.6032 52.4932 24.4161 52.3872 24.1895 52.2874C23.9649 52.1876 23.7091 52.1397 23.4263 52.1397C23.1685 52.1397 22.9231 52.1917 22.6903 52.2957C22.4574 52.3997 22.2515 52.5473 22.0706 52.7386C21.8918 52.9299 21.7483 53.1524 21.6443 53.4061C21.5404 53.6618 21.4884 53.9321 21.4884 54.2232C21.4884 54.5143 21.5362 54.8034 21.6298 55.0633C21.7234 55.3232 21.8585 55.5478 22.0311 55.7349C22.2058 55.9221 22.4137 56.0676 22.6549 56.1695C22.8961 56.2714 23.1706 56.3213 23.4762 56.3213C23.7819 56.3213 24.0481 56.2735 24.2768 56.1799C24.5055 56.0863 24.6885 55.9803 24.8278 55.8618L25.2229 56.6977H25.2187Z"
            fill="#D49C4D"
          />
          <path
            d="M28.8613 54.2377C28.8613 53.8364 28.9383 53.4538 29.0942 53.092C29.2502 52.7301 29.4643 52.4099 29.7367 52.1313C30.0112 51.8527 30.3273 51.6343 30.6849 51.4763C31.0446 51.3183 31.4335 51.2393 31.8514 51.2393C32.2694 51.2393 32.6436 51.3183 33.0055 51.4763C33.3673 51.6343 33.6875 51.8527 33.9661 52.1313C34.2447 52.4099 34.461 52.7301 34.6169 53.092C34.7729 53.4538 34.8498 53.8364 34.8498 54.2377C34.8498 54.639 34.7729 55.0361 34.6169 55.3959C34.461 55.7556 34.2427 56.0716 33.9619 56.3482C33.6812 56.6247 33.361 56.8389 33.0013 56.9907C32.6416 57.1446 32.2569 57.2194 31.8431 57.2194C31.4293 57.2194 31.0446 57.1425 30.6849 56.9907C30.3252 56.8389 30.0091 56.6268 29.7367 56.3544C29.4643 56.0841 29.2481 55.7681 29.0942 55.4063C28.9383 55.0444 28.8613 54.6535 28.8613 54.2356V54.2377ZM29.8261 54.2293C29.8261 54.5246 29.8781 54.797 29.9821 55.0507C30.0861 55.3044 30.2337 55.5248 30.4208 55.714C30.608 55.9053 30.8263 56.0529 31.0758 56.1611C31.3253 56.2692 31.5915 56.3212 31.8764 56.3212C32.1612 56.3212 32.4253 56.2671 32.6686 56.1611C32.9119 56.0529 33.124 55.9053 33.3049 55.714C33.4837 55.5227 33.6272 55.3023 33.7311 55.0507C33.8351 54.7991 33.8871 54.5288 33.8871 54.2377C33.8871 53.9466 33.833 53.67 33.727 53.4163C33.6209 53.1627 33.4754 52.9422 33.2924 52.7489C33.1094 52.5555 32.8952 52.4058 32.6499 52.2976C32.4024 52.1895 32.1384 52.1375 31.8535 52.1375C31.5686 52.1375 31.3046 52.1916 31.0571 52.3018C30.8097 52.412 30.5955 52.5617 30.4104 52.753C30.2254 52.9443 30.0819 53.1668 29.98 53.4205C29.8781 53.6742 29.8282 53.9445 29.8282 54.2293H29.8261Z"
            fill="#D49C4D"
          />
          <path
            d="M44.0033 57.3655L39.5078 53.0072L39.7885 53.1195L39.8051 57.1576H38.8569V51.1025H38.9048L43.3192 55.4608L43.1092 55.3964L43.0926 51.3022H44.0262V57.3655H44.0012H44.0033Z"
            fill="#D49C4D"
          />
          <path d="M47.9855 51.3018H51.9009V52.2021H50.3892V57.1551H49.4556V52.2021H47.9834V51.3018H47.9855Z" fill="#D49C4D" />
          <path d="M55.8496 51.3018H56.7916V57.1572H55.8496V51.3018Z" fill="#D49C4D" />
          <path
            d="M66.3046 57.3655L61.809 53.0072L62.0897 53.1195L62.1064 57.1576H61.1582V51.1025H61.206L65.6205 55.4608L65.4105 55.3964L65.3938 51.3022H66.3274V57.3655H66.3025H66.3046Z"
            fill="#D49C4D"
          />
          <path
            d="M71.5483 55.0507C71.5483 55.2815 71.6086 55.4915 71.7292 55.6828C71.8498 55.8741 72.0099 56.028 72.2074 56.1444C72.4049 56.2629 72.6274 56.3212 72.8749 56.3212C73.1223 56.3212 73.3635 56.2629 73.5673 56.1444C73.7711 56.0259 73.9333 55.872 74.0539 55.6828C74.1745 55.4936 74.2348 55.2815 74.2348 55.0507V51.3037H75.1518V55.0673C75.1518 55.4957 75.0499 55.872 74.8461 56.1964C74.6423 56.5208 74.3678 56.7724 74.0227 56.9533C73.6775 57.1321 73.2949 57.2236 72.877 57.2236C72.459 57.2236 72.0785 57.1342 71.7354 56.9533C71.3923 56.7745 71.1178 56.5208 70.9141 56.1964C70.7103 55.872 70.6084 55.4957 70.6084 55.0673V51.3037H71.5503V55.0507H71.5483Z"
            fill="#D49C4D"
          />
          <path
            d="M79.061 54.2386C79.061 53.8373 79.138 53.4547 79.2939 53.0929C79.4499 52.7311 79.664 52.4109 79.9364 52.1323C80.2109 51.8536 80.527 51.6353 80.8846 51.4773C81.2443 51.3192 81.6332 51.2402 82.0511 51.2402C82.4691 51.2402 82.8434 51.3192 83.2052 51.4773C83.567 51.6353 83.8872 51.8536 84.1658 52.1323C84.4444 52.4109 84.6607 52.7311 84.8166 53.0929C84.9726 53.4547 85.0495 53.8373 85.0495 54.2386C85.0495 54.64 84.9726 55.0371 84.8166 55.3968C84.6607 55.7566 84.4424 56.0726 84.1617 56.3492C83.8809 56.6257 83.5607 56.8399 83.201 56.9917C82.8413 57.1456 82.4566 57.2204 82.0428 57.2204C81.629 57.2204 81.2443 57.1435 80.8846 56.9917C80.5249 56.8399 80.2088 56.6278 79.9364 56.3554C79.664 56.0851 79.4478 55.769 79.2939 55.4072C79.138 55.0454 79.061 54.6545 79.061 54.2366V54.2386ZM80.0258 54.2303C80.0258 54.5256 80.0778 54.798 80.1818 55.0517C80.2858 55.3053 80.4334 55.5258 80.6205 55.715C80.8077 55.9063 81.026 56.0539 81.2755 56.162C81.5251 56.2702 81.7912 56.3221 82.0761 56.3221C82.3609 56.3221 82.625 56.2681 82.8683 56.162C83.1116 56.0539 83.3237 55.9063 83.5046 55.715C83.6834 55.5237 83.8269 55.3033 83.9309 55.0517C84.0348 54.8001 84.0868 54.5297 84.0868 54.2386C84.0868 53.9475 84.0327 53.671 83.9267 53.4173C83.8206 53.1636 83.6751 52.9432 83.4921 52.7498C83.3091 52.5565 83.095 52.4067 82.8496 52.2986C82.6022 52.1905 82.3381 52.1385 82.0532 52.1385C81.7683 52.1385 81.5043 52.1926 81.2568 52.3028C81.0094 52.413 80.7952 52.5627 80.6101 52.754C80.4251 52.9453 80.2816 53.1678 80.1797 53.4215C80.0778 53.6751 80.0279 53.9455 80.0279 54.2303H80.0258Z"
            fill="#D49C4D"
          />
          <path
            d="M89.9008 55.0507C89.9008 55.2815 89.9611 55.4915 90.0817 55.6828C90.2023 55.8741 90.3624 56.028 90.56 56.1444C90.7575 56.2629 90.98 56.3212 91.2274 56.3212C91.4749 56.3212 91.7161 56.2629 91.9198 56.1444C92.1236 56.0259 92.2858 55.872 92.4064 55.6828C92.527 55.4936 92.5873 55.2815 92.5873 55.0507V51.3037H93.5043V55.0673C93.5043 55.4957 93.4024 55.872 93.1986 56.1964C92.9949 56.5208 92.7204 56.7724 92.3752 56.9533C92.03 57.1321 91.6474 57.2236 91.2295 57.2236C90.8116 57.2236 90.431 57.1342 90.0879 56.9533C89.7449 56.7745 89.4704 56.5208 89.2666 56.1964C89.0628 55.872 88.9609 55.4957 88.9609 55.0673V51.3037H89.9029V55.0507H89.9008Z"
            fill="#D49C4D"
          />
          <path
            d="M100.846 52.5022C100.638 52.3899 100.413 52.2943 100.178 52.2173C99.9435 52.1404 99.7168 52.1009 99.5027 52.1009C99.2136 52.1009 98.9849 52.1654 98.8186 52.2943C98.6522 52.4232 98.5691 52.5999 98.5691 52.8245C98.5691 52.9846 98.6273 53.126 98.7416 53.2466C98.856 53.3672 99.0057 53.4712 99.1887 53.5606C99.3717 53.65 99.563 53.7332 99.7667 53.8143C99.9435 53.8787 100.118 53.9557 100.289 54.043C100.459 54.1324 100.617 54.2385 100.759 54.3653C100.9 54.4921 101.012 54.6522 101.095 54.8477C101.179 55.0432 101.22 55.2781 101.22 55.5505C101.22 55.8624 101.143 56.141 100.991 56.3906C100.84 56.6401 100.615 56.8418 100.32 56.9936C100.025 57.1474 99.6732 57.2223 99.2656 57.2223C99.0182 57.2223 98.7791 57.1953 98.5462 57.1412C98.3133 57.0871 98.0929 57.0144 97.8829 56.925C97.6729 56.8335 97.4816 56.7233 97.3027 56.5964L97.7207 55.8728C97.8496 55.9685 97.9972 56.0579 98.1636 56.139C98.3299 56.2201 98.5004 56.2845 98.6751 56.3323C98.8498 56.3802 99.0161 56.4051 99.1783 56.4051C99.3405 56.4051 99.5048 56.3781 99.6732 56.3199C99.8416 56.2637 99.9809 56.1743 100.087 56.0516C100.193 55.929 100.247 55.7626 100.247 55.5526C100.247 55.3862 100.199 55.2449 100.102 55.1305C100.004 55.0161 99.8811 54.9142 99.7272 54.8248C99.5754 54.7354 99.407 54.6564 99.224 54.5878C99.0473 54.5192 98.8622 54.4401 98.6689 54.3549C98.4755 54.2696 98.2967 54.1636 98.1345 54.0368C97.9702 53.9099 97.8413 53.756 97.7436 53.5751C97.6479 53.3922 97.598 53.1697 97.598 52.9077C97.598 52.5854 97.6708 52.3067 97.8143 52.0676C97.9598 51.8285 98.1677 51.6393 98.4381 51.5C98.7084 51.3606 99.0161 51.2858 99.3592 51.2754C99.773 51.2754 100.124 51.3253 100.418 51.4251C100.711 51.5249 100.971 51.6455 101.201 51.7911L100.84 52.5064L100.846 52.5022Z"
            fill="#D49C4D"
          />
          <path d="M43.6788 60.043L44.7829 62.7669L44.3504 62.6796L45.3527 60.043H46.2364L44.5105 64L42.7847 60.043H43.6788Z" fill="#D49C4D" />
          <path d="M48.0415 60.043H50.6199V60.7624H48.7859V61.5692H50.4099V62.2887H48.7859V63.1058H50.6927V63.8253H48.0415V60.043Z" fill="#D49C4D" />
          <path
            d="M56.001 63.9793L53.1336 61.3906L53.354 61.5132L53.3686 63.8255H52.6138V59.8955H52.645L55.45 62.4739L55.2857 62.4011L55.2712 60.0431H56.0218V63.9793H56.001Z"
            fill="#D49C4D"
          />
          <path d="M57.8911 60.043H60.4404V60.7624H59.5213V63.8253H58.7769V60.7624H57.8932V60.043H57.8911Z" fill="#D49C4D" />
          <path
            d="M62.9773 62.428C62.9773 62.5486 63.0127 62.6629 63.0854 62.7731C63.1582 62.8833 63.2518 62.9728 63.3703 63.0414C63.4888 63.11 63.6177 63.1433 63.7591 63.1433C63.913 63.1433 64.0502 63.1079 64.1709 63.0414C64.2915 62.9728 64.385 62.8833 64.4557 62.7731C64.5264 62.6629 64.5618 62.5486 64.5618 62.428V60.043H65.2958V62.4425C65.2958 62.7191 65.2272 62.9665 65.0899 63.1786C64.9527 63.3928 64.7676 63.5612 64.5347 63.6818C64.3018 63.8024 64.044 63.8648 63.7591 63.8648C63.4743 63.8648 63.2227 63.8045 62.9919 63.6818C62.7611 63.5612 62.576 63.3928 62.4388 63.1786C62.3015 62.9644 62.2329 62.7191 62.2329 62.4425V60.043H62.9773V62.428Z"
            fill="#D49C4D"
          />
          <path
            d="M68.4918 60.043C68.7288 60.043 68.9388 60.0721 69.1239 60.1324C69.309 60.1927 69.4628 60.2779 69.5897 60.3902C69.7144 60.5025 69.8101 60.6356 69.8745 60.7915C69.939 60.9475 69.9723 61.1221 69.9723 61.3176C69.9723 61.4715 69.9494 61.6253 69.9036 61.7792C69.8579 61.9331 69.783 62.0745 69.677 62.2013C69.573 62.3282 69.4337 62.43 69.2632 62.507C69.0927 62.5839 68.8785 62.6234 68.6269 62.6234H68.0925V63.8253H67.3481V60.043H68.4939H68.4918ZM68.6207 61.904C68.733 61.904 68.8286 61.8853 68.9035 61.8478C68.9783 61.8104 69.0386 61.7626 69.0802 61.7044C69.1239 61.6461 69.153 61.5858 69.1717 61.5214C69.1904 61.459 69.2008 61.3987 69.2008 61.3446C69.2008 61.303 69.1946 61.2511 69.1821 61.1887C69.1696 61.1263 69.1447 61.0618 69.1073 60.9953C69.0698 60.9308 69.0116 60.8747 68.9347 60.831C68.8577 60.7874 68.7538 60.7645 68.6228 60.7645H68.0884V61.9061H68.6165L68.6207 61.904ZM69.3194 62.3656L70.2551 63.8253H69.3859L68.4294 62.3864L69.3173 62.3656H69.3194Z"
            fill="#D49C4D"
          />
          <path d="M72.02 60.043H74.5984V60.7624H72.7644V61.5692H74.3884V62.2887H72.7644V63.1058H74.6712V63.8253H72.02V60.043Z" fill="#D49C4D" />
          <path
            d="M78.6137 60.958C78.4702 60.879 78.3226 60.8146 78.1749 60.7626C78.0252 60.7106 77.8859 60.6856 77.757 60.6856C77.5969 60.6856 77.47 60.7189 77.3765 60.7834C77.2829 60.8478 77.2371 60.9456 77.2371 61.0766C77.2371 61.166 77.2725 61.245 77.3432 61.3157C77.4139 61.3864 77.5033 61.4488 77.6156 61.5028C77.7279 61.5569 77.8422 61.6068 77.9628 61.6525C78.073 61.6941 78.1812 61.7419 78.2893 61.7981C78.3974 61.8542 78.4931 61.9249 78.5804 62.0081C78.6657 62.0913 78.7343 62.1973 78.7863 62.3221C78.8382 62.4468 78.8632 62.6007 78.8632 62.7816C78.8632 62.9729 78.8133 63.1517 78.7135 63.316C78.6137 63.4803 78.4681 63.6134 78.2768 63.7152C78.0855 63.8171 77.8485 63.867 77.5678 63.867C77.4243 63.867 77.2767 63.8525 77.129 63.8234C76.9793 63.7943 76.8338 63.7506 76.6903 63.6903C76.5468 63.63 76.4075 63.5551 76.2744 63.4616L76.6029 62.8814C76.6924 62.9459 76.788 63.0041 76.8941 63.054C76.998 63.1039 77.1041 63.1413 77.2101 63.1704C77.3162 63.1975 77.4139 63.212 77.5033 63.212C77.5927 63.212 77.6821 63.1996 77.7736 63.1725C77.8651 63.1476 77.94 63.1039 78.0003 63.0415C78.0606 62.9792 78.0897 62.8939 78.0897 62.7837C78.0897 62.7088 78.0647 62.6402 78.0148 62.5778C77.9649 62.5155 77.8984 62.4593 77.8152 62.4073C77.732 62.3554 77.6405 62.3117 77.5407 62.2743C77.4243 62.2306 77.3037 62.1786 77.181 62.1204C77.0583 62.0622 76.9419 61.9915 76.8338 61.9062C76.7256 61.823 76.6383 61.717 76.5718 61.5922C76.5052 61.4675 76.4719 61.3136 76.4719 61.1327C76.4719 60.9144 76.5219 60.7231 76.6175 60.563C76.7152 60.4029 76.8525 60.2739 77.0313 60.1804C77.2101 60.0868 77.4139 60.0327 77.6489 60.0244C77.9545 60.0244 78.202 60.0598 78.3912 60.1325C78.5804 60.2053 78.7509 60.2906 78.9027 60.3904L78.6199 60.9601L78.6137 60.958Z"
            fill="#D49C4D"
          />
          <path
            d="M103.372 52.5931C103.219 52.5931 103.079 52.5578 102.952 52.485C102.826 52.4143 102.726 52.3145 102.651 52.1897C102.576 52.065 102.539 51.9236 102.539 51.7676C102.539 51.6117 102.576 51.4661 102.651 51.3414C102.726 51.2166 102.826 51.1189 102.952 51.0503C103.079 50.9796 103.219 50.9463 103.372 50.9463C103.526 50.9463 103.67 50.9816 103.794 51.0503C103.919 51.121 104.019 51.2166 104.094 51.3414C104.169 51.4661 104.206 51.6075 104.206 51.7676C104.206 51.9277 104.169 52.065 104.094 52.1897C104.019 52.3145 103.919 52.4122 103.794 52.485C103.67 52.5557 103.528 52.5931 103.372 52.5931ZM103.368 52.4434C103.495 52.4434 103.609 52.4143 103.713 52.3561C103.817 52.2979 103.898 52.2168 103.959 52.1149C104.019 52.013 104.048 51.8965 104.048 51.7697C104.048 51.6429 104.019 51.5243 103.959 51.4225C103.898 51.3206 103.817 51.2395 103.713 51.1813C103.609 51.121 103.495 51.0918 103.368 51.0918C103.241 51.0918 103.133 51.121 103.031 51.1813C102.929 51.2416 102.848 51.3227 102.79 51.4225C102.73 51.5243 102.701 51.6387 102.701 51.7697C102.701 51.9007 102.73 52.0109 102.79 52.1149C102.85 52.2188 102.932 52.2979 103.031 52.3561C103.133 52.4143 103.246 52.4434 103.368 52.4434ZM103.073 51.2956H103.377C103.46 51.2956 103.53 51.3164 103.591 51.3601C103.651 51.4037 103.68 51.4724 103.68 51.5701C103.68 51.6533 103.655 51.7219 103.605 51.778C103.555 51.8321 103.476 51.8612 103.37 51.8612H103.221V52.2105H103.071V51.2977L103.073 51.2956ZM103.343 51.7344C103.41 51.7344 103.456 51.7198 103.483 51.6886C103.51 51.6595 103.522 51.62 103.522 51.5743C103.522 51.5181 103.505 51.4786 103.47 51.4536C103.435 51.4308 103.397 51.4183 103.358 51.4183H103.229V51.7323H103.343V51.7344ZM103.508 51.8196L103.707 52.2084H103.537L103.352 51.8238L103.505 51.8196H103.508Z"
            fill="#D49C4D"
          />
        </g>
        <defs>
          <clipPath id="clip0_68_422">
            <rect width="121.72" height="64" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </motion.div>
  )
}

export default Logo
