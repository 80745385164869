import React from 'react'
import DivisionSolo from './DivisionSolo'
import styles from './Divisions.module.css'
import { InteractiveMarquee } from '../Values/Marquee'

const Divisions = () => {
  const divisionsData = [
    {
      imageSrc: './img/PA.png',
      headlineText: '.PA',
      subHeadlineText: '.PA',
      descriptionText: 'Founder and investor with expertise in digital infrastructure. Leads global teams to scale businesses in AI, software, and telecom.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '.VC',
      subHeadlineText: '.VC',
      descriptionText:
        'Investing in transformative technologies and visionary founders. We provide capital and strategic support to high-potential startups across technology and data-driven sectors.'
    },
    {
      imageSrc: './img/PA03.png',
      headlineText: '.JV',
      subHeadlineText: '.JV',
      descriptionText:
        'Leverage our marketing, business setup, and payment processing services to scale your company with ease, especially in high-growth regions like Dubai and Asia. Conduit for businesses migrating and expanding to the middle east, europe and north america – and the moon!'
    }
  ]
  const divisionsDataTwo = [
    {
      imageSrc: './img/PA.png',
      headlineText: '',
      subHeadlineText: 'Partnership',
      descriptionText: 'Success is built on collaboration; we work closely with startups, investors, and partners to achieve common goals.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '',
      subHeadlineText: 'Integrity',
      descriptionText: 'We maintain the highest standards of transparency and ethics in everything we do.'
    },
    {
      imageSrc: './img/PA03.png',
      headlineText: '',
      subHeadlineText: 'Disruption',
      descriptionText: 'Disruption We believe in the power of technology to transform industries and lives.'
    }
  ]

  return (
    <div className={styles.divisions}>
      <div className={styles.mqText}>
        <InteractiveMarquee speed="4">
          <div className={styles.titleWrapper}>
            <span>Private Accelerator</span>
            <div className={styles.iconWrapper}></div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>

          <div className={styles.titleWrapper}>
            Venture Capital
            <div className={styles.iconWrapper}></div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>
          <div className={styles.titleWrapper}>
            Joint Ventures
            <div className={styles.iconWrapper}></div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>
        </InteractiveMarquee>
      </div>
      {/* Pass all data to a single DivisionSolo component */}
      <DivisionSolo divisionData={divisionsData} />
      <DivisionSolo divisionData={divisionsDataTwo} />
    </div>
  )
}

export default Divisions
