import React, { useEffect, useState } from 'react'
import { client } from '../../prismic'
import styles from './Blogposts.module.css'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, A11y } from 'swiper/modules'
import { format } from 'date-fns'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const BlogPosts = () => {
  const [post, setPost] = useState(null)

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await client.getSingle('blogposts')
        console.log('Fetched post data:', response)
        setPost(response)
      } catch (error) {
        console.error('Error fetching blog post:', error)
      }
    }

    fetchPost()
  }, [])

  const renderPlaceholder = () => (
    <div className={styles.customSlide}>
      <div className={`${styles.textWrapper} ${styles.skeleton}`}>{/* Skeleton content */}</div>
      <div className={styles.skeletonImage}></div>
    </div>
  )

  const sampleData = [
    {
      date: '2023-10-01',
      posturl: '#',
      meta: 'Sample Meta',
      title: 'Sample Title',
      desc: 'Sample Description',
      image: { url: 'https://via.placeholder.com/300x200' }
    }
    // Add more sample items if needed
  ]

  return (
    <div className={styles.blog}>
      <h1>News</h1>
      <div className={styles.blogWrapper}>
        {post && post.data && post.data.group && post.data.group.length > 0 ? (
          <Swiper
            style={{ padding: '20px' }}
            modules={[Navigation, Pagination, A11y]}
            // spaceBetween={20}
            // slidesPerView={3.15}
            navigation
            pagination={{ clickable: true }}
            breakpoints={{
              480: {
                slidesPerView: 1.75,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 1.75,
                spaceBetween: 15
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20
              }
            }}>
            {post.data.group.map((item, index) => {
              const formattedDay = format(new Date(item.date), 'dd')
              const formattedShortDate = format(new Date(item.date), 'MMM / yy')

              return (
                <SwiperSlide key={index}>
                  <a href={item.posturl} className={styles.boxLink} target="_blank" rel="noopener noreferrer">
                    <div className={styles.customSlide}>
                      <div className={styles.textWrapper}>
                        <div className={styles.boxHeader}>
                          <div className={styles.date}>
                            <p>{formattedDay}</p>
                          </div>
                          <div className={styles.dateSmall}>
                            <div>{formattedShortDate}</div>
                            <div className={styles.meta}>{item.meta}</div>
                          </div>
                        </div>
                        <div className={styles.titleText}>
                          <h3>{item.title}</h3>
                          <p>{item.desc}</p>
                        </div>
                      </div>
                      {item.image?.url && <img src={item.image.url} alt={item.title} />}
                    </div>
                  </a>
                </SwiperSlide>
              )
            })}
          </Swiper>
        ) : (
          // Use sampleData for testing
          <Swiper
            style={{ padding: '20px' }}
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={1.5}
            navigation
            breakpoints={{
              480: {
                slidesPerView: 2.15,
                spaceBetween: 10
              },
              768: {
                slidesPerView: 2.5,
                spaceBetween: 15
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 20
              }
            }}>
            {sampleData.map((item, index) => (
              <SwiperSlide key={index}>
                {/* Render sample slide */}
                <div className={styles.customSlide}>{/* Slide content */}</div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

export default BlogPosts
