import { useState, useRef, useEffect } from 'react'
import DivisionSolo from './DivisionSolo'
import styles from './ContactList.module.css'
import { InteractiveMarquee } from '../Values/Marquee'
import RevealingText from '../RevealingText/RevealingText'
import { motion, useMotionValue, useTransform } from 'framer-motion'
import SineWave from '../SineWave/SineWave'

const Divisions = () => {
  const [amplitude, setAmplitude] = useState(125)
  const sineWaveRef = useRef(null)
  const scrollY = useMotionValue(0)
  const amplitudeTransform = useTransform(scrollY, [0, 1], [125, 0])

  useEffect(() => {
    const updateScroll = () => {
      const element = sineWaveRef.current
      if (element) {
        const rect = element.getBoundingClientRect()
        const windowHeight = window.innerHeight

        // Calculate scroll position as a ratio (0 at "top top", 1 at "end end")
        const progress = Math.min(1, Math.max(0, ((windowHeight - rect.top) / windowHeight) * 1.25))

        // Update scrollY with progress (0 to 1)
        scrollY.set(progress)
      }
    }

    window.addEventListener('scroll', updateScroll)
    return () => window.removeEventListener('scroll', updateScroll)
  }, [scrollY])

  // Sync the transformed amplitude value to the amplitude state
  useEffect(() => {
    return amplitudeTransform.on('change', (latest) => setAmplitude(latest))
  }, [amplitudeTransform])

  const divisionsData = [
    {
      imageSrc: './img/PA.png',
      headlineText: '.PA',
      subHeadlineText: 'Feasibility Study',
      descriptionText:
        'The D&I team conducts thorough market research and feasibility studies to evaluate the viability and potential of new products and technologies.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '.VC',
      subHeadlineText: 'MVP Development',
      descriptionText: 'The D&I team creates and implements marketing strategies to support the strategies and launch of Minimum Viable Products.'
    }
  ]
  const divisionsDataTwo = [
    {
      imageSrc: './img/PA.png',
      headlineText: '',
      subHeadlineText: 'Seed Funding',
      descriptionText: 'The focus is on ensuring the portfolio of companies gains traction and achieves a growth trajectory in appropriate markets.'
    },
    {
      imageSrc: './img/PA02.png',
      headlineText: '',
      subHeadlineText: 'Growth Funding',
      descriptionText: 'Marketing efforts are scaled to support the growth and expansion of startups.'
    }
  ]

  return (
    <div className={styles.divisions}>
      <div className={styles.mqText}>
        <InteractiveMarquee speed="4">
          <div className={styles.titleWrapper}>
            <span>The D&I Team Process</span>
            <div className={styles.iconWrapper}>{/* <Partnership className={styles.icon01} /> */}</div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>

          <div className={styles.titleWrapper}>
            <span>Disruption & Intelligence</span>
            <div className={styles.iconWrapper}>{/* <Integrity className={styles.icon02} /> */}</div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>
          <div className={styles.titleWrapper}>
            <span>The D&I Team Process</span>
            <div className={styles.iconWrapper}>{/* <Innovation className={styles.icon03} /> */}</div>
          </div>
          <div>
            <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect opacity="0.25" x="17.8529" y="2.15074" width="21" height="21" transform="rotate(45 17.8529 2.15074)" stroke="white" strokeWidth="3" />
              <rect x="17.853" y="9.91104" width="10.025" height="10.025" transform="rotate(45 17.853 9.91104)" fill="#D9D9D9" />
            </svg>
          </div>
        </InteractiveMarquee>
      </div>
      <div className={styles.introText}>
        <RevealingText text="Whichever stage you are in, the D&I team loves to receive your projects. They will review each project thoroughly, putting your submission through an internal review. " />
      </div>
      <div className={styles.labelWrapper}>
        <div className={styles.labelSingle}>Feasability</div>
        <div className={styles.labelSingle}>MVP</div>
        <div className={styles.labelSingle}>Seed</div>
        <div className={styles.labelSingle}>Growth</div>
      </div>
      <motion.div ref={sineWaveRef} className={styles.alignment}>
        <SineWave amplitude={amplitude} frequency={0.35} vertPosition={-20} timeOffset={0} dotColor="#ffffff" />
        <SineWave amplitude={amplitude} frequency={0.35} vertPosition={+20} timeOffset={9} dotColor="#868686" />
      </motion.div>
      <div className={styles.listWrapper}>
        {/* Pass all data to a single DivisionSolo component */}
        <DivisionSolo divisionData={divisionsData} />
        <DivisionSolo divisionData={divisionsDataTwo} />
      </div>
    </div>
  )
}

export default Divisions
