import { useLoader, useFrame } from '@react-three/fiber'
import { MeshTransmissionMaterial } from '@react-three/drei'
import { GLTFLoader } from 'three-stdlib'
import { useRef, useEffect, useState } from 'react'

export function Prism({ onRayOver, onRayOut, onRayMove, ...props }) {
  const { nodes } = useLoader(GLTFLoader, '/gltf/prism03.glb')
  const groupRef = useRef()
  const [isMobile, setIsMobile] = useState(false)

  // Check if the user is on a mobile device
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768) // Adjust breakpoint as needed
    }
    checkMobile()
    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  useFrame((state, delta) => {
    if (groupRef.current) {
      if (!isMobile) {
        groupRef.current.position.x = groupRef.current.position.x || 0 // Keep x-axis movement for larger screens if needed
      } else {
        groupRef.current.position.x = 0 // Ensure no offset on x-axis for mobile
      }

      groupRef.current.rotation.y += delta * 0.5 // Adjust speed by changing multiplier
    }
  })

  return (
    <group ref={groupRef} {...props}>
      {/* A low-res, invisible representation of the prism that gets hit by the raycaster */}
      <mesh visible={false} scale={1.0} rotation={[Math.PI / 2, Math.PI, 0]} onRayOver={onRayOver} onRayOut={onRayOut} onRayMove={onRayMove}>
        <boxGeometry args={[1, 1, 1]} />
      </mesh>
      {/* The visible hi-res prism */}
      <mesh rotation={[0.5, 0.5, 0]} renderOrder={10} scale={2} dispose={null} geometry={nodes.Cube.geometry}>
        <MeshTransmissionMaterial clearcoat={1} transmission={1} thickness={0.9} roughness={0} anisotropy={0.1} chromaticAberration={1} toneMapped={false} />
      </mesh>
    </group>
  )
}
