import * as React from 'react'
import styles from './HeroContact.module.css'
import { AnimatePresence, motion, useInView } from 'motion/react'
import heroTexts from './heroTexts.json'

function HeroContact({ text = heroTexts.defaultText, subText = heroTexts.subText }) {
  const ref = React.useRef(null)
  const isInView = useInView(ref, { once: true })

  return (
    <div className={styles.heroWrapper}>
      {/* <p className={styles.heroSubText}>
        <AnimatePresence>
          {subText.split('').map((char, i) => (
            <motion.span
              ref={ref}
              key={i}
              initial={{ opacity: 0, y: -5 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              exit="hidden"
              className="charWrapper"
              transition={{ duration: 0.5, delay: i * 0.02 }}>
              {char === ' ' ? <span>&nbsp;</span> : char}
            </motion.span>
          ))}
        </AnimatePresence>
      </p> */}
      <h1 className={styles.heroH1}>
        <AnimatePresence>
          {text.split('').map((char, i) => (
            <motion.span
              ref={ref}
              key={i}
              initial={{ opacity: 0, y: -5 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              exit="hidden"
              className="charWrapper"
              transition={{ duration: 0.5, delay: i * 0.02 }}>
              {char === ' ' ? <span>&nbsp;</span> : char}
            </motion.span>
          ))}
        </AnimatePresence>
      </h1>
    </div>
  )
}

export default HeroContact
